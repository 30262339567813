<template>
  <div id="tutoringWrap" class="cont_home">
    <section id="tutoringContent">
      <!-- content -->
      <article>
        <LandingSwiper :titleGroup="titleGroup" :swiperCts="swiperCts" :swiperImg="swiperImg"></LandingSwiper>
        <LandingCard></LandingCard>
      </article>
    </section>
  </div>
</template>
<script lang="ts">
import { LandingSwiper, LandingCard } from '@/components';

export default {
  name: 'Topics',
  components: {
    LandingSwiper,
    LandingCard,
  },
  data() {
    return {
      titleGroup: ['전례 없는 생생함', '이것은 교재가 아니다'],
      swiperImg: [
        '//cdn.tutoring.co.kr/uploads/home/img/topic/topic_card_01.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/topic_card_02.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/topic_card_03.png',
        '//cdn.tutoring.co.kr/uploads/home/img/topic/topic_card_04-2.png',
      ],
      swiperCts: [
        '가장 트랜디한 영어 공부',
        '앱 설치 하나로 수업 준비 완료',
        '체계적인 설계로 완성된 전문 토픽',
        '좋아하는 토픽으로 시작',
      ],
    };
  },
};
</script>

<style></style>
