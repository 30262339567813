import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "tutoringWrap",
  class: "cont_home"
}
const _hoisted_2 = { id: "tutoringContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LandingSwiper = _resolveComponent("LandingSwiper")!
  const _component_LandingCard = _resolveComponent("LandingCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createCommentVNode(" content "),
      _createElementVNode("article", null, [
        _createVNode(_component_LandingSwiper, {
          titleGroup: $data.titleGroup,
          swiperCts: $data.swiperCts,
          swiperImg: $data.swiperImg
        }, null, 8 /* PROPS */, ["titleGroup", "swiperCts", "swiperImg"]),
        _createVNode(_component_LandingCard)
      ])
    ])
  ]))
}